<template>
  <svg v-if="compact" width="128" height="63" viewBox="0 0 128 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M63.2131 11.7526C77.231 11.4832 88.2667 15.2548 96.8267 22.6791C103.045 28.0727 108.246 35.6454 112.268 45.6467C109.203 44.8917 105.964 44.5215 102.573 44.5215C95.399 44.5215 88.9611 46.181 83.5973 49.7923C77.5879 46.1253 71.1232 44.1979 64.2757 44.1979C57.2723 44.1979 50.5495 46.2127 44.1689 49.9949C38.0103 46.1206 31.0708 44.1979 23.4993 44.1979C20.7653 44.1979 18.1493 44.4516 15.6814 44.982C19.2979 35.7838 24.0824 28.693 29.8914 23.4963C38.1921 16.0706 49.1296 12.0232 63.2131 11.7526ZM9.82851 61.4166C10.0384 61.2253 10.235 61.0184 10.4162 60.797C12.9782 57.8239 16.9889 55.9371 23.4993 55.9371C29.6392 55.9371 34.9062 57.6433 39.512 61.015L39.6938 61.1503C42.1964 63.0133 45.6034 63.0901 48.1874 61.3418C53.6911 57.6181 59.0238 55.9371 64.2757 55.9371C69.4346 55.9371 74.4405 57.5573 79.4026 61.0801L79.5061 61.1541C82.2606 63.1246 86.0058 62.9538 88.5695 60.7408C91.8469 57.9117 96.3323 56.2607 102.573 56.2607C109.139 56.2607 114.229 58.0926 118.249 61.4864C118.437 61.6511 118.634 61.8036 118.84 61.9429C119.378 62.3076 119.959 62.572 120.56 62.7383C121.467 62.9906 122.446 63.0279 123.424 62.8063C123.942 62.6895 124.446 62.5023 124.921 62.2458C126.451 61.4203 127.52 59.9599 127.873 58.2959C128.018 57.6127 128.042 56.8951 127.928 56.173C127.894 55.9515 127.847 55.7318 127.787 55.5151C123.13 37.4749 115.517 23.3503 104.518 13.8108C93.4022 4.16947 79.3898 -0.299596 62.9876 0.0155562C46.651 0.329447 32.8403 5.10715 22.0645 14.7471C11.3826 24.303 4.22946 38.1473 0.178326 55.6457C0.118718 55.8829 0.0742537 56.1228 0.044812 56.364C-0.165626 58.0565 0.37625 59.7025 1.43574 60.9265C1.98837 61.5649 2.68183 62.0886 3.4828 62.4442C3.99072 62.6707 4.52237 62.8205 5.06113 62.895C6.83849 63.1446 8.57209 62.5615 9.82851 61.4166Z" fill="white"/>
  </svg>
  <svg v-else width="180" height="200" viewBox="0 0 180 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M90.166 200C93.4473 200 97.373 199.395 101.943 198.184C117.373 192.402 125.088 181.27 125.088 164.785V117.617C125.088 116.719 125.029 115.937 124.912 115.273C124.912 115.156 124.902 115.078 124.883 115.039C124.863 115 124.814 114.922 124.736 114.805C123.135 110.781 120.596 108.77 117.119 108.77H116.65L115.654 108.828H115.127C115.01 108.828 114.873 108.887 114.717 109.004C110.693 110.566 108.682 113.867 108.682 118.906V167.246C108.682 169.863 108.018 172.422 106.689 174.922C102.939 180.82 97.373 183.77 89.9902 183.77C84.4043 183.77 79.6777 182.031 75.8105 178.555C72.6465 175.977 71.0254 171.23 70.9473 164.316L70.8301 116.797C70.8301 116.367 70.791 115.918 70.7129 115.449C70.7129 115.293 70.7031 115.186 70.6836 115.127C70.6641 115.068 70.5957 114.961 70.4785 114.805C68.8379 110.703 66.2402 108.652 62.6855 108.652C61.5527 108.77 60.791 108.906 60.4004 109.062C56.4941 110.625 54.541 113.262 54.541 116.973L54.7168 166.25C54.7559 173.594 56.7871 180.215 60.8105 186.113C68.3105 195.371 78.0176 200 89.9316 200H90.166Z" fill="#FFFFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.0267 78.2397C25.0747 78.2397 19.5903 80.8572 16.1027 84.9716C13.2457 88.3419 8.19753 88.7581 4.82721 85.9011C1.45689 83.0442 1.04072 77.996 3.89766 74.6257C11.2138 65.9949 21.7471 62.2397 34.0267 62.2397C44.3462 62.2397 53.8044 64.8604 62.1984 70.1408C70.8948 64.9859 80.0577 62.2397 89.6031 62.2397C98.9359 62.2397 107.747 64.8667 115.938 69.8647C123.248 64.9426 132.023 62.6809 141.8 62.6809C154.063 62.6809 164.865 66.2318 173.642 73.7085C177.006 76.5734 177.41 81.6226 174.545 84.9861C171.68 88.3496 166.631 88.7538 163.267 85.8888C157.773 81.2084 150.804 78.6809 141.8 78.6809C133.295 78.6809 127.181 80.9311 122.714 84.787C119.22 87.8032 114.116 88.036 110.361 85.3504L110.22 85.2494C103.457 80.448 96.6344 78.2397 89.6031 78.2397C82.445 78.2397 75.1767 80.531 67.6755 85.6062C64.1535 87.9891 59.5099 87.8844 56.099 85.3452L55.8512 85.1607C49.5737 80.5653 42.3951 78.2397 34.0267 78.2397Z" fill="#FFFFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M133.969 32.9107C122.302 22.7916 107.261 17.6512 88.1548 18.0182C68.9596 18.3871 54.0522 23.9035 42.7387 34.0244C31.3296 44.2309 22.8201 59.7981 17.7954 81.5961C16.803 85.9015 12.5082 88.5872 8.20286 87.5947C3.89749 86.6023 1.21181 82.3076 2.20425 78.0022C7.71919 54.0773 17.4796 35.153 32.071 22.0997C46.7579 8.96082 65.5814 2.44902 87.8474 2.0212C110.203 1.59166 129.301 7.68281 144.452 20.8236C159.471 33.8502 169.858 53.1503 176.202 77.8057C177.303 82.0846 174.727 86.4459 170.448 87.5468C166.169 88.6478 161.808 86.0715 160.707 81.7926C154.876 59.1318 145.767 43.1438 133.969 32.9107Z" fill="#FFFFFF"/>
  </svg>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  components: {}
};
</script>

<style lang="scss" scoped></style>